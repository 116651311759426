<template>
    <section v-if="mobile">
        <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
            <MobileHeader />
             <h1 class="font-heading text-h4 text-center text-sepiaBlack dark:text-white"> Az idővonalam </h1>
            <input type='text' id='quicksearchInput' placeholder="Search" v-model="quicksearchInput" ref="input" v-on:change="searchChange">

            <Button v-show="save_enabel == 'none'" buttonType="secondary" text="Idővonal szerkesztése" class="block center mx-auto my-2" v-on:click="edit()"/>
            <!-- <input type='text' id='quicksearchInput' placeholder="Keresés" v-model="quicksearchInput" ref="input" v-on:change="searchChange"> -->
            <ul>
                <li v-for="(post) of posts_mobile" :key="post.id">
                    <EntityTimeLineMobile :content="post" :saveEnabel="save_enabel" :deletingElements="deleting_elements" @addElementMobile="addElementMobile" @removeElementMobile="removeElementMobile"/>
                </li> 
            </ul>
            <Button v-show="save_enabel == 'block'" buttonType="secondary" :text="deletingElementsCount()" class="block center mx-auto my-2" v-on:click="edit()"/>
            <p v-show="save_enabel == 'block'" class="block center mx-auto my-2"> {{deleting_elements.length}} elem kiválasztva</p>
        </div>
    </section>
    <section v-else>
        <div class="max-w-screen-tv-main-axis max-h-screen-tv-cross-axis mx-auto py-2">
            <Header :language="postsData.lang" @langchanged="langchanged_" :backArrow="true" :back="false">
            </Header>
            <h1 class="font-heading text-h1 text-halfColonialWhite"> Az idővonalam 
               <Button buttonType="primary" :text="button_text" class="inline-block" v-on:click="edit()"/>
            </h1>
            <div class="flex" v-if="landscape">
                <ul class="flex flex-nowrap mx-10 pb-10 pt-24 overflow-x-scroll hide-scroll-bar relative">
                    <!-- <span class="border-t border-redDamask absolute w-full top-24"></span> -->
                    <li v-for="(post, index) in posts" :key="post.id" class="inline-block relative min-w-80 w-80 border-t border-redDamask">
                        <span class="block absolute left-24 top-2" v-on:click="deleteElement(post.id, index)" :style="'display: ' +save_enabel+ ';'">
                            <i class="bi bi-x" width="16" height="16"></i> 
                            Törlés 
                        </span>
                        <div :class="'pt-' + marginGrid(index)">
                            <span class="absolute block left-20 -top-24 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24">{{ newIndex(index) }}</span>
                            <span :class="'h-' + lineHeight(index) + ' ' + 'border-l border-redDamask block absolute left-20 top-0 transform -translate-x-1/2' "></span>
                            <EntityTimeLine class="block" tv :content="post" :itemIndex="index" />
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else>
                <ul class="mx-10 pb-10 pl-24 relative">
                    <!-- <span class="border-t border-redDamask absolute w-full top-24"></span> -->
                    <li v-for="(post, index) in posts" :key="post.id" class="block relative border-l border-redDamask pb-16">
                        <span class="block absolute left-4 top-20" v-on:click="deleteElement(post.id, index)" :style="'display: ' +save_enabel+ ';'">
                            <i class="bi bi-x" width="16" height="16"></i> 
                            Törlés 
                        </span>
                        <div :class="'pl-' + marginGridPortre(index)">
                            <span class="absolute block -left-16 top-4 transform -translate-x-1/2 font-heading text-8xl text-transparent bg-clip-text bg-gradient-to-b from-halfColonialWhite to-halfColonialWhite-transparent tracking-tighter opacity-20 w-24	">{{ newIndex(index) }}</span>
                            <span :class="'w-' + lineHeightPortre(index) + ' ' + 'border-t border-redDamask block absolute left-0 top-20' "></span>
                            <EntityTimeLinePortre class="block" tv :content="post" :itemIndex="index" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>

import Header from "@/components/Header.vue";
import MobileHeader from "@/components/mobile/Header.vue";
import EntityTimeLine from "@/components/tablet/EntityTimeLine.vue";
import EntityTimeLineMobile from "@/components/mobile/EntityTimeLine.vue";
import EntityTimeLinePortre from "@/components/tablet/EntityTimeLinePortre.vue";
import Button from "@/components/Button.vue";

export default {
    name: "Tablet",
    components: {
        Header,
        MobileHeader,
        EntityTimeLineMobile,
        EntityTimeLinePortre,
        EntityTimeLine,
        Button,
    },
    mounted() {
        this.getPosts();
        this.myEventHandler();
    },
    data(){
        return {
            posts: [],
            posts_mobile: [],
            baseURL: process.env.VUE_APP_AXIOS_URL,
            postsData: {
                user_pin: this.$route.query.user_pin ? this.$route.query.user_pin : (localStorage.getItem('user_pin') ? localStorage.getItem('user_pin') : null),
                lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            },
            pagination: {
                prevPage: '',
                nextPage: '',
                totalPages: '',
                from: '',
                to: '',
                total: '',
            },
            deleting_elements: [],
            save_enabel: 'none',
            button_text: "Szerkesztés",
            mobile: false,
            landscape: true,
        }
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        myEventHandler() {
            if (window.innerWidth <= 768) {
                this.mobile = true;
            } else {
                this.mobile = false;
                if (screen.orientation.type == 'landscape-primary') {
                    this.landscape = true;
                } else {
                    this.landscape = false;
                }
            }
        },
        getPosts() {
            this.axios.get(this.baseURL + `/wp-json/wp/v2/last-timeline-pin`, {params: this.postsData}, {})
            .then(response => {
                this.posts = response.data;
                this.posts_mobile = response.data;
                if (response.data.length < 1) {
                    alert("Empty timeline");
                    if (localStorage.getItem('user_pin')) {
                        this.$router.push('/mobile/menu');
                    } else {
                        this.$router.push('/pin-check');
                    }
                }
            }).catch( (error) => {
                console.log(error);
            });
        },
        searchChange() {
            const search_input = document.querySelector('#quicksearchInput').value;
            if (search_input.length > 2 ) {
                this.posts_mobile = [];
                for (let i = 0; i < this.posts.length; i++) {
                    if (this.posts[i].title.toLowerCase().includes(search_input.toLowerCase())) {
                        this.posts_mobile.push(this.posts[i]);
                    }
                }
            } else {
                this.posts_mobile = this.posts
            }
        },
        langchanged_(value) {
            this.postsData.page = 1;
            this.postsData.lang = value;
            this.getPosts();
        },
        gridRowCount(value) {
            return parseInt((value % 4)+1);
        },
        marginGrid(value) {
            return parseInt((value % 3) * 16);
        },
        lineHeight(value) {
            return parseInt(20 + ((value % 3) * 16));
        },
        newIndex: function(value) {
            if(value >= 10) {
                return value + 1;
            } 
            return `0${value+1}`
        },
        marginGridPortre(value) {
            return parseInt(((value % 2) + 1) * 20);
        },
        lineHeightPortre(value) {
            return parseInt(20 + ((value % 2) * 16));
        },
        deleteElement(value, index) {
            this.deleting_elements.push(value);
            this.posts.splice(index,1);
        },
        addElementMobile(value) {
            this.deleting_elements.push(value);
        },
        removeElementMobile(value) {
            this.deleting_elements.splice(this.deleting_elements.indexOf(value), 1);
        },
        deletingElementsCount(){
            return this.deleting_elements.length > 0 ? "Törlés" : "Vissza";
        },
        edit() {
            if(this.posts.length > 0 || this.deleting_elements.length > 0) {
                if (this.save_enabel == 'none') {
                    this.save_enabel = 'block';
                    this.button_text = "Mentés";
                    this.deleting_elements = [];
                } else {
                    if (this.deleting_elements.length > 0) {
                        this.axios.delete(this.baseURL + `/wp-json/wp/v2/timeline_delete_elements`, {data: { 'user_pin': this.postsData.user_pin, 'entity_ids': this.deleting_elements}}, {})
                        .then(response => {
                            if (response.data.error) {
                                alert(response.data.error);
                            } else {
                                window.location.reload();
                            }
                        }).catch( (error) => {
                            console.log(error);
                        });
                    } else {
                        this.save_enabel = 'none';
                        this.button_text = "Szerkesztés";
                    }
                }
            }
        },
    },
    watch: {
        quicksearchInput: function(value) {
            console.log(value);
            this.onChange();
        },
    },
};
</script>
